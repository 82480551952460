import React from 'react'
import { graphql } from 'gatsby'
import { MDXRenderer } from 'gatsby-plugin-mdx'

import Typography from '@material-ui/core/Typography'

import SEO from '@components/seo'
import Layout from '@components/layout'
import Page from '@components/Page'

export default function Template({ data, pageContext: { breadcrumbs } }) {
  const { mdx } = data
  const { frontmatter, body } = mdx

  return (
    <Layout breadcrumbs={[]}>
      <SEO title={frontmatter.title} />

      <Page>
        <Typography variant="h1">{frontmatter.title}</Typography>

        <MDXRenderer>{body}</MDXRenderer>
      </Page>
    </Layout>
  )
}

export const pageQuery = graphql`
  query($slug: String!) {
    mdx(frontmatter: { slug: { eq: $slug } }) {
      body
      frontmatter {
        slug
        title
      }
    }
  }
`
